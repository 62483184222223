

import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';



import React from "react"
import i18next from '../i18n/config';
// import ImgHeader from "../img/A_glowna_1/tlo_glowna.jpg";


import ImgOurCoffeeBack from "../img/D_kawa_ziarnista_18/iko_powrot_nasze_kawy.png";
import LogoBonAroma from "../img/N_gotowy_produkt_20/logo_Bon_Aroma.png";
import LogoMirador from "../img/N_gotowy_produkt_20/logo_Mirador.png";
import LogoNoble from "../img/N_gotowy_produkt_20/logo_Noble.png";
import LogoIntenso from "../img/N_gotowy_produkt_20/logo_Intenso.png";
import LogoLeCafe from "../img/N_gotowy_produkt_20/logo_le_cafe.png";
import ProductsBonAroma from "../img/N_gotowy_produkt_20/produkty_bon_aroma.png";
import ProductsMirador from "../img/N_gotowy_produkt_20/produkty_mirador.png";
import ProductsNoble from "../img/N_gotowy_produkt_20/produkty_noble.png";
import ProductsIntenso from "../img/N_gotowy_produkt_20/produkty_intenso.png";
import ProductsLeCafe from "../img/N_gotowy_produkt_20/le_cafe_torby.png";
import ProductsLeCafeSloiki from "../img/N_gotowy_produkt_20/le_cafe_sloiki.png";



export default function Header(props) {



    return (
            <React.Fragment>
                <section class="finished firstSection">
                    <h1>{i18next.t('FinishedProductsH1')}</h1>
                    <p>{i18next.t('FinishedProductsP')}</p>
                    <div class="features">
                        <div class="feature"   onClick={() => document.scrolltosection("bonaroma")} >
                            <a>
                                <div><img loading="lazy" src= {LogoBonAroma}  alt="Instanta Bon Aroma Logo" /></div>
                                <p>
                                    {i18next.t('FinishedProductsLogoBonAroma')}
                                </p>
                            </a>
                        </div>
                        <div class="feature" onClick={() => document.scrolltosection("mirador")}  >
                            <a>
                                <div><img loading="lazy" src=
                                          {LogoMirador}
                                          alt="Instanta Mirador Logo" /></div>
                                <p>
                                    {i18next.t('FinishedProductsLogoMirador')}
                                </p>
                            </a>
                        </div>
                        <div class="feature" onClick={() => document.scrolltosection("noble")}  >
                            <a>
                                <div><img loading="lazy" src=
                                          {LogoNoble}
                                          alt="Instanta Noble Cafe Logo" /></div>
                                <p>
                                    {i18next.t('FinishedProductsLogoNoble')}
                                </p>
                            </a>
                        </div>
                        <div class="feature" onClick={() => document.scrolltosection("intenso")}  >
                            <a>
                                <div><img loading="lazy" src=
                                          {LogoIntenso}
                                          alt="Instanta Intenso Logo" /></div>
                                <p>
                                    {i18next.t('FinishedProductsLogoIntenso')}
                                </p>
                            </a>
                        </div>
                        <div class="feature" onClick={() => document.scrolltosection("lecafe")}  >
                            <a>
                                <div><img loading="lazy" src=
                                          {LogoLeCafe}
                                          alt="Instanta Le Cafe Logo" /></div>
                                <p>
                                    {i18next.t('FinishedProductsLogoLe')}
                                </p>
                            </a>
                        </div>
                    </div>

                    <aside class="mininavi">
                        <div>
                            <Link to=
                                  {'/Collaboration/'}
                                  > <img loading="lazy" src={ImgOurCoffeeBack}
                                  alt="Instanta Coffee Back Icon" />
                            <p>
                                {i18next.t('YourProductMiniMenu1')}
                            </p>
                            </Link>
                        </div>
                    </aside>
                </section>

                <section id="bonaroma" class="bonaroma fp">

                    <div class="FinishDesc">
                        <h2>
                            {i18next.t('FinishedProductsLogoBonAroma')}
                        </h2>
                        <p>
                            {i18next.t('FinishedProductsDescBonAroma')}
                        </p>
                        <div class="link">
                            <a href='http://www.bonaroma.eu'>
                                <p>
                                    {i18next.t('FinishedProductsLink1')}
                                </p>
                            </a>
                        </div>
                    </div>
                    <div class="FinishImg">
                        <img loading="lazy" src=
                             {ProductsBonAroma}
                             alt="Instanta Bon Aroma Products" />
                    </div>
                </section>

                <section id="mirador" class="mirador fp">

                    <div class="FinishDesc">
                        <h2>
                            {i18next.t('FinishedProductsLogoMirador')}
                        </h2>
                        <p>
                            {i18next.t('FinishedProductsDescMirador')}
                        </p>
                    </div>
                    <div class="FinishImg">
                        <img loading="lazy" src=
                             {ProductsMirador}
                             alt="Instanta Mirador Products" />
                    </div>
                </section>

                <section id="noble" class="nobleessence fp">

                    <div class="FinishDesc">
                        <h2>
                            {i18next.t('FinishedProductsLogoNoble')}
                        </h2>
                        <p>
                            {i18next.t('FinishedProductsDescNoble')}
                        </p>
                        <div class="link">
                            <a href='http://www.noble-coffee.com/'>
                                <p>
                                    {i18next.t('FinishedProductsLink2')}
                                </p>
                            </a>
                        </div>
                    </div>
                    <div class="FinishImg">
                        <img loading="lazy" src=
                             {ProductsNoble}
                             alt="Instanta Noble Essence Products" />
                    </div>
                </section>

                <section id="intenso" class="intenso fp">

                    <div class="FinishDesc">
                        <h2>
                            {i18next.t('FinishedProductsLogoIntenso')}
                        </h2>
                        <p>
                            {i18next.t('FinishedProductsDescIntenso')}
                        </p>
                    </div>
                    <div class="FinishImg">
                        <img loading="lazy" src=
                             {ProductsIntenso}
                             alt="Instanta Intenso Products" />
                    </div>
                </section>


                <section id="lecafe" class="malaczarna fp">

                    <div class="FinishDesc">
                        <h2>
                            {i18next.t('FinishedProductsLogoLe')}
                        </h2>
                        <p>
                            {i18next.t('FinishedProductsDescLe')}
                        </p>
                    </div>
                    <div class="FinishImg">
                        <img loading="lazy" src=
                             {ProductsLeCafe}
                             alt="Instanta Le Cafe Products" />
                        <img loading="lazy" src=
                             {ProductsLeCafeSloiki}
                             alt="Instanta Le Cafe Sloiki" />
                    </div>
                </section>




            </React.Fragment>

            )


}
